// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZCwunsJYlMJmnF7mSg_m-n6Bqzo2NxAE",
  authDomain: "gyst-planner-v1-firebase.firebaseapp.com",
  projectId: "gyst-planner-v1-firebase",
  storageBucket: "gyst-planner-v1-firebase.appspot.com",
  messagingSenderId: "667841007628",
  appId: "1:667841007628:web:a220458b3623bcc0ebde29",
  measurementId: "G-LX87T0HKKV"
};

export default firebaseConfig;

